import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import { TokenTable } from '~/components/TokensTable/TokenTable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader">
    </PageHeader>
    <p>{`Ved å bruke våre breakpoints kan du enkelt tilpasse ditt design for å sikre at innholdet presenteres på en optimal måte på tvers av ulike enheter og skjermstørrelser. `}</p>
    <p>{`Om du ønsker mer hjelp til å lage responsive løsninger kan du bruke vår `}<a parentName="p" {...{
        "href": "/komponenter/ressurser/grid"
      }}>{`grid-pakke.`}</a></p>
    <TokenTable tokenKey="breakpoints" mdxType="TokenTable" />
    <p>{`I vår utils-pakke finnes det en mixin for bruk av våre breakpoints:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@mixin for-desktop {
  @media screen and (min-width: #{t.$breakpoints-large}) {
    @content;
  }
}

@mixin for-large-desktop {
  @media screen and (min-width: #{t.$breakpoints-extra-large}) {
    @content;
  }
}
`}</code></pre>
    <p>{`Eksempel på bruk:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@use '@entur/utils/dist/breakpoints.scss';

.main-content{
    padding: 16px;

    @include for-desktop {
        padding: 24px;
    }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      